import './src/styles/global.css';

function addJS({ code, src, id }) {
  var s = document.createElement(`script`);
  s.type = `text/javascript`;
  s.id = id;
  if (code) {
    s.innerText = code;
  } else if (src) {
    s.setAttribute('src', src);
  }

  document.getElementsByTagName(`body`)[0].appendChild(s);
}

export const onClientEntry = () => {
  const urlQuery = new URLSearchParams(window.location.search);
  let source = urlQuery.get('utm_source');
  let reference = urlQuery.get('utm_trackingId');
  if (source && reference) {
    window.__source = window.localStorage.setItem('source', source);
    window.__reference = window.localStorage.setItem('reference', reference);
  }
};

export const onRouteUpdate = ({ location }) => {
  const urlQuery = new URLSearchParams(location.search);
  let isNew = urlQuery.has('new');
  const form = document.getElementById('ccApply');
  window.__source = window.localStorage.getItem('source');
  window.__reference = window.localStorage.getItem('reference');
  if (isNew) window.localStorage.clear();
  window.__loanAmount = window.localStorage.getItem('__loanAmount');
  window.__loanRepay = window.localStorage.getItem('__loanRepay');
  window.__loanTerm = window.localStorage.getItem('__loanTerm');
  window.__loanTotal = window.localStorage.getItem('__loanTotal');
  window.__loanCost = window.localStorage.getItem('__loanCost');

  if (form) {
    addJS({
      id: 'CWConfig',
      code: `window.clearChoice_conf = window.clearChoice_conf || {};clearChoice_conf = {source: window.__source, reference: window.__reference, formUrl: "https://familio.co.uk/apply",  elemId: "ccApply", theme: "familiov2", loanRepay: +window.__loanRepay, loanCost: window.__loanCost, loanTotal: window.__loanTotal, loanAmount: +window.__loanAmount,alwaysShowFirstStage:false, loanTerm: +window.__loanTerm, brokerName: "Choose Wisely", brokerAddress1: 18, brokerAddress2: "Christchurch Road", brokerTown: "Bournemouth", brokerCounty: "Dorset", brokerPostCode: "BH1 3NE", brokerSignature: "Marc Biles", sun: "180277", mode: "Results",onSubmit:function(formData){ga('send', { hitType: 'event',eventCategory: 'Form', eventAction: 'Submit',eventLabel: 'la:' + window.__loanAmount + ' lt: ' + window.__loanTerm})}  };  `,
    });
    addJS({
      id: 'CWScript',
      src: `https://app.familio.co.uk/releases/v2/ccLoader.js`,
    });
  } else {
    document
      .querySelectorAll('[src^="https://app.familio.co.uk"]')
      .forEach(el => {
        el.remove();
      });
    document
      .querySelectorAll('[href^="https://app.familio.co.uk"]')
      .forEach(el => {
        el.remove();
      });
  }
};
