exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apply-js": () => import("./../../../src/pages/apply.js" /* webpackChunkName: "component---src-pages-apply-js" */),
  "component---src-pages-form-jsx": () => import("./../../../src/pages/form.jsx" /* webpackChunkName: "component---src-pages-form-jsx" */),
  "component---src-pages-pfr-js": () => import("./../../../src/pages/pfr.js" /* webpackChunkName: "component---src-pages-pfr-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-consumer-duty-js": () => import("./../../../src/templates/consumer-duty.js" /* webpackChunkName: "component---src-templates-consumer-duty-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-content-js": () => import("./../../../src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-social-js": () => import("./../../../src/templates/social.js" /* webpackChunkName: "component---src-templates-social-js" */),
  "component---src-templates-support-js": () => import("./../../../src/templates/support.js" /* webpackChunkName: "component---src-templates-support-js" */)
}

